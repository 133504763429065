import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import Button from '../components/button'
import InputMask from 'react-input-mask'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  margin-top: 1em;
  text-align: center;
  button {
    width: 100%;
    max-width: 380px;
  }
`

function EstimateForm({
  style,
  showLabels,
  pageTitle,
  pageProps,
  pageLocation,
  showPlaceHolders,
}) {
  const [spamFilter, updateSpamFilterStatus] = useState(false)
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (
      phone.includes('(171') ||
      phone.includes('(191') ||
      phone.includes('(121')
    ) {
      updateSpamFilterStatus(true)
      return false
    }
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'free-estimate-form',
        subject: `Grime Busters Form Submission - ${firstName} ${lastName}`,
        firstname: firstName,
        lastname: lastName,
        url: url,
        landingURL: landingURL,
        email: email,
        phone: phone,
        description: description,
        referrer: document.referrer,
        ['bot-field']: botField,
      }),
    })
      .then(() =>
        navigate('/thanks', {
          state: { firstname: firstName, lastName: lastName, email: 'test' },
        })
      )
      .catch((error) => alert(error))

    e.preventDefault()
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [url, setUrl] = useState(pageLocation)
  const [landingURL, setLandingURL] = useState(
    typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('landingURL')
      ? window.localStorage.getItem('landingURL')
      : 'Not landing URL found'
  )
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [description, setDescription] = useState('')
  const [botField, setBotField] = useState('')

  return (
    <>
      <form
        onSubmit={(e) => handleSubmit(e)}
        name="free-estimate-form"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className={
          style
            ? style + ' mt-4 mb-0 mx-auto'
            : 'contact-form-default-style mt-4 mb-0 mx-auto'
        }
      >
        <input
          type="hidden"
          name="bot-field"
          value={botField}
          onChange={(e) => setBotField(e.target.value)}
        />

        <input type="hidden" name="form-name" value="free-estimate-form" />
        <input type="hidden" name="firstname" />
        <input type="hidden" name="lastname" />
        <input type="hidden" name="email" />
        <input type="hidden" name="phone" />
        <input type="hidden" name="url" />
        <input type="hidden" name="landingURL" />
        <input type="hidden" name="description" />
        <input type="text" className="hidden" name="referrer" />
        <input type="hidden" id="subject" name="subject" />

        <div className="grid grid-cols-1 row-gap-3 col-gap-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="firstname"
              className={`block text-base font-medium leading-5 text-gray-800 ${
                !showLabels ? 'sr-only' : ''
              }`}
            >
              First Name
            </label>
            <div className="mt-1 ">
              <input
                id="firstname"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={showPlaceHolders ? 'First Name' : ''}
                className="form-control  "
                required={true}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="lastname"
              className={`block text-base font-medium leading-5 text-gray-800 ${
                !showLabels ? 'sr-only' : ''
              }`}
            >
              Last Name
            </label>
            <div className="mt-1 ">
              <input
                id="lastname"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="form-control"
                placeholder={showPlaceHolders ? 'Last Name' : ''}
                required={true}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="email"
              className={`block text-base font-medium leading-5 text-gray-800 ${
                !showLabels ? 'sr-only' : ''
              }`}
            >
              Email address
            </label>
            <div className="mt-1 ">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder={showPlaceHolders ? 'Email' : ''}
                pattern={
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                }
                required={true}
                onChange={(e) => {
                  setEmail(e.target.value)
                  if (
                    e.target.value.match(
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                    ) == null
                  ) {
                    e.target.setCustomValidity(
                      'Please enter a valid domain at the end of your email address'
                    )
                  } else {
                    e.target.setCustomValidity('')
                  }
                }}
                className="form-control "
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="phone"
              className={`block text-base font-medium leading-5 text-gray-800 ${
                !showLabels ? 'sr-only' : ''
              }`}
            >
              Phone
            </label>
            <div className="mt-1 ">
              <InputMask
                type="tel"
                name="phone"
                id="phone"
                placeholder={showPlaceHolders ? 'Phone' : ''}
                className="form-control "
                mask="(999) 999-9999"
                onChange={(e) => {
                  setPhone(e.target.value)
                  if (
                    e.target.value.match(
                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
                    ) == null
                  ) {
                    e.target.setCustomValidity(
                      'Please enter a complete phone number'
                    )
                  } else {
                    e.target.setCustomValidity('')
                  }
                }}
                required
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor="description"
              className={`block text-base font-medium leading-5 text-gray-800 ${
                !showLabels ? 'sr-only' : ''
              }`}
            >
              Tell us about your project. Include address
            </label>
            <div className="mt-1 ">
              <textarea
                id="description"
                type="text"
                rows="3"
                value={description}
                placeholder={
                  showPlaceHolders
                    ? 'Tell us about your project. Include address'
                    : ''
                }
                onChange={(e) => setDescription(e.target.value)}
                className=" form-control "
                required
              ></textarea>
            </div>{' '}
          </div>
        </div>
        <div className="border-t border-gray-200 pt-1 w-full">
          <ButtonContainer>
            {spamFilter && (
              <div className="font-bold bg-red-600 p-2 px-4 mb-2 text-white">
                An Error Occured. Give Us A Call.
              </div>
            )}
            <Button
              role="submit"
              type="submit"
              color="primary"
              className="w-full"
            >
              Request Consultation →
            </Button>
          </ButtonContainer>
        </div>
        <div className={`mt-3`}>
          <p
            style={{ color: '#888', fontSize: '12px', maxWidth: '400px' }}
            className="m-0 text-gray-600 text-center mx-auto text-xs flex items-center justify-center"
          >
            {' '}
            I agree that Grime Busters can email and call me in response to my
            inquiry, as well as with tips and offers for similar services.
          </p>
        </div>
      </form>{' '}
    </>
  )
}

export default EstimateForm
